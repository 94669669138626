import invoices from "../assets/api/invoices";

/**
 * Returns token with prefix
 * 
 * Default prefix is `Hummingbird`
 * @param {String} token token string
 * @return {String} token with prefix
 **/
 export function addTokenPrefix(token, prefix="Hummingbird") {
    return `${prefix} ${token}`
  
}

/**
 * Function for capitalize first letter of every word
 * @param {String} string string to process
 * @returns Capitalized string
 **/
 export function capitalizeFirstLetter(string = '') {
  return (string ?? '').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
export function mergeDeep(...objects) {
    const isObject = obj => obj && typeof obj === 'object';
    
    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];
        
        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        }
        else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = mergeDeep(pVal, oVal);
        }
        else {
          prev[key] = oVal;
        }
      });
      
      return prev;
    }, {});
  }

  /**
  * Transform number to a comma seperated value with dollar
  *
  * @param {Number} value - target number
  * @returns {String} formatted string
  */
  export function formatMoneyByCurrency(value = 0, fractionalDigits = 2) {
    if(isNaN(value) || [null, false].includes(value)) value = 0
    return value.toLocaleString('en-US', {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: fractionalDigits,
      maximumFractionDigits: fractionalDigits
    })
  }

  /**
  * Return a price with proper formatting for its amount type
  *
  * @param {Number} price - price to be formatted
  * @returns {String} formatted string
  */
  export function formatPriceByType(price, type) {
    if(type === "percentage"){
      return this.$options.filters.formatPercentageTwoDecimals(price);
    }else if(type === "fixed" || type === "variable"){
      return this.$options.filters.formatMoney(price);
    }
  }

  /**
  * Check whether the given value is null, undefined and ''
  *
  * @param {String} - target value
  * @returns {Boolean} - true for null, undefined and '' false otherwise
  */
  export function isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0) ||
      (typeof value === "number" && isNaN(value))
    );
  }
  
  /**
   * Note : This request will only work with Hummingbird Authorization token
   * Return gds passthrough request with endpoint and default header
   *
   * @param {String} appId - Application ID
   * @returns {Object} Request details
   */
  export function getPassthroughRequestDetails(appId, endpoint) {
    let request = {
      uri: endpoint,
      headers: {
        "X-storageapi-date": String(Date.now()),
      },
    }
    if (appId && endpoint.includes(":app_id")) {
      request.uri = endpoint.replace(":app_id", appId);
    }
    return request;
  }

  /**
  * Check if country name is US or USA
  *
  * @param {String} - target value
  * @returns {String} - Country full name
  */
  export function parseCountry(countryName, defaultCountry = 'United States') {
    const countryList = ['US', 'USA'];
		const isFound =  countryList.some(country => country === countryName)
    return !countryName || isFound ? defaultCountry : countryName
  }
  
 /**
  * Adjust rich text editor height so that bottom action bar always sticks to bottom
  *
  * @param {String} - Rich text editor element
  * @returns {String} - Calculated Rich text editor height
  */
 export function stickBottomActionBarToBottom(richTextEditor, bottomActionBar, editorType=''){
  let offSet = offSetValue(editorType)
  let richTextEditorFinalComputedHeight = 0
  if (bottomActionBar && richTextEditor) {
    const rect = bottomActionBar.getBoundingClientRect()
    const distanceBelowViewport = rect.bottom - window.innerHeight
    const richTextEditorHeight = richTextEditor.offsetHeight
    const richTextEditorHeightComputed = richTextEditorHeight - distanceBelowViewport
    richTextEditorFinalComputedHeight =  Math.floor(richTextEditorHeightComputed > 150 ? richTextEditorHeightComputed : 0) - offSet
    }
  return richTextEditorFinalComputedHeight
}


 /**
    * Returns offset height to be applied
    *
    * @param {String} - screen width
    * @returns {String} - Calculated offset height
    */
 export function offSetValue(editorType) {
  if(editorType === 'text-area')
    return 0
  return 10;
}
