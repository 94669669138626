import Vue from 'vue';

const DEFAULT_NOTIFICATION_CONFIG = {
    TITLE: 'Warning',
    ICON: 'mdi-alert',
    ICON_COLOR: '#101318',
    DIVIDER_COLOR: '#00848E',
    BACKGROUND_COLOR: '#F8E5E1',
    CLOSE_HOVER_COLOR: '#DFE3E8',
}

const NOTIFICATION_TYPE_CONFIG = {
    success: {
        TITLE: 'Success',
        ICON: 'mdi-check',
        ALLOW_MULTIPLE: false,
        ICON_COLOR: '#02AD0F',
        DIVIDER_COLOR: '#02AD0F',
        BACKGROUND_COLOR: '#E1FAE3',
        CLOSE_HOVER_COLOR: '#C5EDC9',
    },
    error: {
        TITLE: 'Warning',
        ICON: 'mdi-alert',
        ALLOW_MULTIPLE: false,
        ICON_COLOR: '#FB4C4C',
        DIVIDER_COLOR: '#FB4C4C',
        BACKGROUND_COLOR: '#F8E5E1',
        CLOSE_HOVER_COLOR: '#EFCFCC',
    },
    sms: {
        ICON: 'mdi-message-arrow-right-outline',
        ALLOW_MULTIPLE: true,
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    email: {
        ICON: 'mdi-email-send-outline',
        ALLOW_MULTIPLE: true,
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    create_spaces: {
        ICON: 'mdi-custom-add-spaces',
        ICON_COLOR: '#101318',
        DIVIDER_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    property_amenities: {
        ICON: 'mdi-alert',
        ICON_COLOR: '#101318',
        DIVIDER_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    document_download: {
        TITLE: 'Generating Document',
        ICON: 'mdi-file',
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        DIVIDER_COLOR: '#101318',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    bulk_leases_update: {
        TITLE: 'Updating Leases',
        ICON: 'mdi-account-convert',
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        DIVIDER_COLOR: '#101318',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    gate_access_sync: {
        TITLE: 'Syncing Gate Access',
        ICON: 'mdi-gate',
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        DIVIDER_COLOR: '#101318',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    bulk_space_area_change: {
        ICON: 'mdi-facility-custom-3',
        ICON_COLOR: '#101318',
        DIVIDER_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    },
    document_retry_update: {
        TITLE: 'Retrying Documents',
        ICON: 'mdi-file-restore',
        ICON_COLOR: '#101318',
        BACKGROUND_COLOR: '#FFFFFF',
        DIVIDER_COLOR: '#101318',
        CLOSE_HOVER_COLOR: '#DFE3E8',
    }
}
function generateUniqueID() {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 26; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
function checkDependentTypes(payloadType, elementType) {
    switch (payloadType) {
        case 'success':
        case 'error':
            if (elementType === 'success' || elementType === 'error') {
                return true
            }
            break;
        default:
            return false
    }
}

function getNotificationSettings(payload) {
    payload.id = payload.id ? payload.id : generateUniqueID();
    payload.value = true;
    payload.progress = payload.progress? payload.progress: false;
    payload.percentage = payload.percentage? payload.percentage: 0;
    payload.list = payload.list ? payload.list : [];
    payload.type = payload.type ? payload.type : 'error';
    payload.persistent = payload.persistent ? payload.persistent : false;
    payload.timeout = payload.timeout ? payload.timeout : payload.type === 'error' ? 15000 : 6000;
    payload.loading = payload.loading ? payload.loading : false;
    payload.action = payload.action ? payload.action : false;
    payload.closeColor = payload.closeColor ? payload.closeColor : '#637381'
    payload.description = payload.description ? payload.description : (payload.type === 'error') ? 'There are errors in your form, correct them before continuing.' : '';
    payload.note = payload.note?.length ? payload.note : '';
    if(NOTIFICATION_TYPE_CONFIG[payload.type]) {
        payload.title = (payload.title) ? payload.title : NOTIFICATION_TYPE_CONFIG[payload.type].TITLE;
        payload.backgroundColor = (payload.backgroundColor) ? payload.backgroundColor : NOTIFICATION_TYPE_CONFIG[payload.type].BACKGROUND_COLOR;
        payload.icon = (payload.icon) ? payload.icon : NOTIFICATION_TYPE_CONFIG[payload.type].ICON;
        payload.iconColor = (payload.iconColor) ? payload.iconColor : NOTIFICATION_TYPE_CONFIG[payload.type].ICON_COLOR;
        payload.closeHoverColor = (payload.closeHoverColor) ? payload.closeHoverColor : NOTIFICATION_TYPE_CONFIG[payload.type].CLOSE_HOVER_COLOR;
        payload.dividerColor = (payload.dividerColor) ? payload.dividerColor : NOTIFICATION_TYPE_CONFIG[payload.type].DIVIDER_COLOR;
    } else {
        payload.title = DEFAULT_NOTIFICATION_CONFIG.TITLE;
        payload.backgroundColor = DEFAULT_NOTIFICATION_CONFIG.BACKGROUND_COLOR;
        payload.icon = DEFAULT_NOTIFICATION_CONFIG.ICON;
        payload.iconColor = DEFAULT_NOTIFICATION_CONFIG.ICON_COLOR;
        payload.closeHoverColor = NOTIFICATION_TYPE_CONFIG[payload.type].CLOSE_HOVER_COLOR;
        payload.dividerColor = DEFAULT_NOTIFICATION_CONFIG.DIVIDER_COLOR;
    }
    return payload;
}

class GlobalNotifications {
    constructor() {
        this.namespaced = true;

        this.state = {
            inSnappedZone: true,
            notifications: [],
        }

        this.getters = {
            getAllNotifications: state => state.notifications,
            getInSnappedZone: state => state.inSnappedZone
        },

        this.actions = {
            setInSnappedZone({ commit }, payload) {
                commit('snappedZone', payload);
            },
            setGlobalNotification({ state, commit }, payload) {
                let index = state.notifications.findIndex((n) => n.id === payload.id);
                if (index >= 0) {
                    return
                }
                let notificationPayload = getNotificationSettings(payload);
                if (!NOTIFICATION_TYPE_CONFIG[notificationPayload.type].ALLOW_MULTIPLE) {
                    state.notifications.forEach((element, index) => {
                        if (element.type === payload.type || checkDependentTypes(notificationPayload.type, element.type)) {
                            commit('removeGlobalNotification', index);
                        }
                    });
                }
                commit('addGlobalNotification', notificationPayload);
            },
            updateGlobalNotification({ state, commit }, payload) {
                if(!payload.index) payload.index = 0;
                commit('updateGlobalNotification', payload);
            },
            removeNonPersistentGlobalNotifications({ state, commit }) {
                state.notifications.forEach((element, index) => {
                    if (!element.persistent) {
                        commit('removeGlobalNotification', index);
                    }
                });
            },
            removeGlobalNotificationById({ state, commit }, id) {
                let index = state.notifications.findIndex((n) => n.id === id);
                if (index >= 0) {
                    commit('removeGlobalNotification', index);
                }
            },
            removeGlobalNotificationByType({ state, commit }, payload) {
                state.notifications.forEach((element, index) => {
                    if (element.type === payload.type) {
                        commit('removeGlobalNotification', index);
                    }
                });
            },
            removeSmsAndEmailNotifications({ state, commit }){
                let length = state.notifications.length
                while (length--) {
                    if (state.notifications[length].type == 'sms' || state.notifications[length].type == 'email') {
                        setTimeout(() => {
                            commit('removeGlobalNotification', length);
                        }, 6000);
                    }
                }
            },
        }

        this.mutations = {
            addGlobalNotification(state, payload) {
                state.notifications.push(payload);
            },
            removeGlobalNotification(state, index) {
                state.notifications.splice(index, 1);
            },
            removeAllGlobalNotification(state) {
                state.notifications = []
            },
            snappedZone: (state, payload) => {
                state.inSnappedZone = payload;
            },
            updateGlobalNotification(state, payload) {
                state.notifications[payload.index][payload.property] = payload.value;
            }
        }
    }
}

export default GlobalNotifications;
