class CloseOfDay {
	constructor(){
		this.namespaced = true;
		this.state = {
			isCorporateCODEnabled: false,
		};
		this.getters = {
			isCorporateCODEnabled: state => state.isCorporateCODEnabled
		};
		this.mutations = {
			updateCorporateCOD(state, payload){
				state.isCorporateCODEnabled = payload;
			},
		}
	}
}

export default CloseOfDay;